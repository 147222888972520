export class ImagesModel {
  public constructor(
    public is_video?: boolean,
    public img1?: string,
    public img2?: string,
    public img3?: string,
    public img4?: string,
    public img5?: string,
    public img6?: string,
    public img7?: string,
    public video1?: string,
    public video2?: string,
    public video3?: string,
    public video4?: string,
    public video5?: string,
    public video6?: string,
    public video7?: string
  ) { }
}
